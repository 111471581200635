import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  LogisticsModel, ResultModel } from '@app/_models';
 
import { Observable } from 'rxjs';

const baseUrl = `/api/v1/logistics`;

@Injectable({
  providedIn: 'root'
})
export class LogisticsService {
  constructor(private http: HttpClient) {}

  findall(): Observable<LogisticsModel[]> {
    return this.http.get<LogisticsModel[]>(`${baseUrl}/all/`);
  }
  addnew(logistics:LogisticsModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}`, logistics);
  }
}
