import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, CustomerModel, PagedCustomers, Pagination, ResultModel, StaffModel } from '@app/_models';
 
import { Observable } from 'rxjs';


const baseUrl = `/api/v1/clients`;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  findall(pagination: Pagination): Observable<PagedCustomers> {
    return this.http.post<PagedCustomers>(`${baseUrl}/all`,pagination);
  }
  

  findbyId(id:number): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(`${baseUrl}/${id}`);
  }
  findAddressesbyId(id:number): Observable<Address[]> {
    return this.http.get<Address[]>(`${baseUrl}/addresses/${id}`);
  }
   
}
