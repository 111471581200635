 
import { AuthGaurd } from './_helpers';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/pages/auth/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent)
  },{
    path: 'reset-password',
    loadComponent: () =>
      import(
        './pages/pages/auth/reset-password/reset-password.component'
      ).then((m) => m.ResetPasswordComponent)
  },{
    path: 'verify',
    loadComponent: () =>
      import('./pages/pages/auth/verify-email/verify-token.component').then(
        (m) => m.VerifyTokenComponent
      )
  },
 
  {
    path: '',
    component: LayoutComponent,canActivate: [AuthGaurd],data: { roles: ['ROLE_MANAGER','ROLE_BOOK','ROLE_ORDERS'] },
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'masters/publisher',
        loadComponent: () =>
        import(
          './pages/masters/publishers/publishers.component'
        ).then((m) => m.PublishersComponent)
      
      },
      {
        path: 'masters/author',
        loadComponent: () =>
        import(
          './pages/masters/authors/authors.component'
        ).then((m) => m.AuthorsComponent)
      
      },
      {
        path: 'masters/logistics',
        loadComponent: () =>
        import(
          './pages/masters/logistics/logistics.component'
        ).then((m) => m.LogisticsComponent)
      
      },
      {
        path: 'masters/languages',
        loadComponent: () =>
        import(
          './pages/masters/languages/languages.component'
        ).then((m) => m.LanguagesComponent)
      
      },  {
        path: 'masters/booktypes',
        loadComponent: () =>
        import(
          './pages/masters/booktypes/booktypes.component'
        ).then((m) => m.BooktypesComponent)
      
      },  {
        path: 'masters/books',
        loadComponent: () =>
        import(
          './pages/masters/books/books.component'
        ).then((m) => m.BooksComponent)
      
      },  {
        path: 'masters/books/new',
        loadComponent: () =>
        import(
          './pages/masters/books/new-book/new-book.component'
        ).then((m) => m.NewBookComponent)
      
      },{
        path: 'masters/books/edit/:id',
        loadComponent: () =>
        import(
          './pages/masters/books/modify-book/modify-book.component'
        ).then((m) => m.ModifyBookComponent)
      
      }, {
        path: 'books/inventory',
        loadComponent: () =>
        import(
          './pages/masters/books/inventory/inventory.component'
        ).then((m) => m.InventoryComponent)
      
      }, {
        path: 'books/inventory/edit/:id',
        loadComponent: () =>
        import(
          './pages/masters/books/inventory/modify-inventory.component'
        ).then((m) => m.ModifyInventoryComponent)
      
      }, {
        path: 'customers',
        loadComponent: () =>
        import(
          './pages/customers/cutomer-list/cutomer-list.component'
        ).then((m) => m.CutomerListComponent)
      
      }, {
        path: 'customers/details/:id',
        loadComponent: () =>
        import(
          './pages/customers/cutomer-details/cutomer-details.component'
        ).then((m) => m.CutomerDetailsComponent)
      
      },
       
      {
        path: 'orders/pending',
        loadComponent: () =>
        import(
          './pages/orders/pending/pending.component'
        ).then((m) => m.PendingComponent)
      
      },
      {
        path: 'orders/pending/details/:id',
        loadComponent: () =>
        import(
          './pages/orders/pending/pending-details.component'
        ).then((m) => m.PendingDetailsComponent)
      
      },
      {
        path: 'orders/despatched',
        loadComponent: () =>
        import(
          './pages/orders/despatched/despatched.component'
        ).then((m) => m.DespatchedComponent)
      
      },{
        path: 'orders/despatched/details/:id',
        loadComponent: () =>
        import(
          './pages/orders/despatched/despatched-details.component'
        ).then((m) => m.DespatchedDetailsComponent)
      
      },
      {
        path: 'orders/completed',
        loadComponent: () =>
        import(
          './pages/orders/completed/completed.component'
        ).then((m) => m.CompletedComponent)
      
      },
      {
        path: 'orders/completed/details/:id',
        loadComponent: () =>
        import(
          './pages/orders/completed/completed-details.component'
        ).then((m) => m.CompletedDetailsComponent)
      
      },
    /*  {
        path: 'masters/country',
        loadComponent: () =>
        import(
          './pages/masters/wallets/wallets-list.component'
        ).then((m) => m.WalletsListComponent)
      
      },
      {
        path: 'masters/author',
        loadComponent: () =>
        import(
          './pages/masters/platforms/platforms.component'
        ).then((m) => m.PlatformsComponent)
      
      },
    */
       
     {
        path: 'staff/list',
        loadComponent: () =>
        import(
          './pages/staff/staff-master.component').then((m) => m.StaffMasterComponent)
      },
     
      {
        path: 'roles/master',
        loadComponent: () =>
        import(
          './pages/staff/roles-master.component').then((m) => m.RolesMasterComponent)
      },
    
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }
    ]
  }
];
