import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultModel, StaffModel } from '@app/_models';
import { RolesModel } from '@app/_models/roles-model';
import { Observable } from 'rxjs';


const baseUrl = `/api/v1/staff`;

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  constructor(private http: HttpClient) {}

  findall(): Observable<StaffModel[]> {
    return this.http.get<StaffModel[]>(`${baseUrl}/all`);
  }
  addnew(staff:StaffModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}`, staff);
  }
  modifyStaff(staff:StaffModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}`, staff);
  }
  changeEmail(staff:StaffModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}/changeEmail`, staff);
  }
  resetPassword(staff:StaffModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}/resetPassword`, staff);
  }
  changeRole(staff:StaffModel):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}/changeRole`, staff);
  }
  changeStatus(id:number):Observable<ResultModel>{
    return this.http.post<ResultModel>(`${baseUrl}/changeStatus/${id}`,id);
  }

  findbyId(id:number): Observable<StaffModel> {
    return this.http.get<StaffModel>(`${baseUrl}/${id}`);
  }
  findRolesInbyId(id:number): Observable<RolesModel[]> {
    return this.http.get<RolesModel[]>(`${baseUrl}/rolesin/${id}`);
  }
  findRolesNotinbyId(id:number): Observable<RolesModel[]> {
    return this.http.get<RolesModel[]>(`${baseUrl}/rolesnotin/${id}`);
  }
}
