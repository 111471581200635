import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResultModel, User } from '@app/_models';
//import { environment } from '@environments/environment';
const baseUrl = '/api/v1/auth';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private refreshTokenTimeout: string | number | NodeJS.Timeout | undefined;
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  private rolesSubject: BehaviorSubject<string[] | null>;
//  public roles: Observable<string[] | []>;
 public roles:string[]=[];
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem('olb-user')!)

    );
    this.user = this.userSubject.asObservable();

  // console.log(this.user,'acctsservice line 26')
   //this.user.subscribe(
  //  value => console.log('Observable emitted the next value: ' + value.accessToken)
  //);
  }

  public isAuthorized(allowedRoles: string[]): boolean {
    const user = this.userValue;
    if (!user) return false;
    if (!user.roles) return false;
    return user.roles.some(role => allowedRoles.includes(role));
  }

  public get userValue() {
    return this.userSubject?.value;
  }
  public get rolesValue() {
    return this.rolesSubject?.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<User>(`${baseUrl}/signin`, { username, email: username, password })
      .pipe(
        map((user) => {
          console.log('Line 41 login service',user)
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('olb-user', JSON.stringify(user));
          this.userSubject.next(user);
          this.user=this.userSubject.asObservable();
          if (user) {
            this.startRefreshTokenTimer();
          }
         
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    this.http
      .post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true })
      .subscribe({
        next: (x) => {
           
         
      //    this.ref.detectChanges();
        },
        error: (err: Error) =>
        {
          this.router.navigate(['/login']);
       },
        complete: () => {
        
        },
      });
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('olb-user');
    //  this.userSubject.next(null);
    //  this.stopRefreshTokenTimer();
    //  this.router.navigate(['/login']);
  }
  logout2() {
    // remove user from local storage and set current user to null
   
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/login']);
    //localStorage.removeItem('user');
    //  this.userSubject.next(null);
    //  this.stopRefreshTokenTimer();
    //  this.router.navigate(['/login']);
  }

  register(signUpRequest: User): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/signup`, signUpRequest);
  }

  getAll() {
    return this.http.get<User[]>(`${baseUrl}/users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${baseUrl}/users/${id}`);
  }
  verifyEmail(token: string): Observable<any> {
    return this.http.post<any>(`${baseUrl}/verify-email`, { token });
  }

  forgotPassword(email: string): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/forgot-password`, { email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string):Observable<ResultModel> {
    return this.http.post<ResultModel>(`${baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword
    });
  }
  update(id: string, params: any) {
    return this.http.put(`${baseUrl}/users/${id}`, params).pipe(
      map((x) => {
        // update stored user if the logged in user updated their own record
        if (id == this.userValue?.id) {
          // update local storage
          const user = { ...this.userValue, ...params };
          localStorage.setItem('olb-user', JSON.stringify(user));

          // publish updated user to subscribers
          this.userSubject.next(user);
        }
        return x;
      })
    );
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/users/${id}`).pipe(
      map((x) => {
        // auto logout if the logged in user deleted their own record
        if (id == this.userValue?.id) {
          this.logout();
        }
        return x;
      })
    );
  }

  getUser(): any {
    return this.http.get<any>(`${baseUrl}/user`, { withCredentials: true });
  }
  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let acceeToken1 = this.userValue?.accessToken;
    if (acceeToken1 == null) {
      acceeToken1 = '';
    }
    const accessToken = JSON.parse(atob(acceeToken1?.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(accessToken.exp * 1000);
    console.log('expires', expires);
    console.log(' expires.getTime()', expires.getTime());
    console.log('  Date.now() ', Date.now());
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    console.log('timeout', timeout);
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  refreshToken() {
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        map((account) => {
          this.userSubject.next(account);
          this.user = this.userSubject.asObservable();
          if (account) {
            this.getUser().subscribe(value=>{
          //    this.userSubject.next(value);
             // this.user = this.userSubject.asObservable();
             this.roles=value.roles
            // this.rolesSubject.next(value.roles);
           //  this.roles =this.rolesSubject.asObservable();
              console.log('Value of account getUser is ',value.roles,this.roles);
            })
           
            this.startRefreshTokenTimer();
          } else {
            this.stopRefreshTokenTimer();
           // this.logout();
           this.router.navigate(['/login']);
          }

          return account;
        })
      );
  }
}
