import { Injectable, inject } from '@angular/core';
import {
  Router,Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGaurd {
  
    constructor(private authService: AccountService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authService.userValue;
     
      if (currentUser) {
        // logged in so return true
        const roles=currentUser.roles;//  as string[];
        console.log('rolesss',roles)
        const allowedRoles=route.data['roles'] as string[];
       if(this.authService.isAuthorized(allowedRoles))
        {
          return true;
        }
        this.authService.logout();
        return false;
      }else{
        this.authService.logout();
        return false;
      }
  
      // not logged in so redirect to login page with the return url
     // this.authService.logout();
      
    }
}
